import React, {Component} from "react";
import {Buffer} from "buffer";
import Image from "antd/lib/image";
import IdcardOutlined from "@ant-design/icons/IdcardOutlined";
import Form from "antd/lib/form";
import {Button, Col, Input, Modal, Row, Spin} from "antd";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import dayjs from "dayjs";
import Cookies from 'universal-cookie';
import CropIdCardImage from "../image/CropIdCardImage";
import Collapse from "antd/lib/collapse";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";

const {Panel} = Collapse;

class BiodataFamily extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.state = {
            submitting: false,
            freezeStatus: false,
            processing: false,
            profileData: {},
            name: Buffer.from(this.cookies.get('_tc-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_tc-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_tc-p'), 'base64').toString(),
            token: this.cookies.get('_tc-t'),
            showOldUploadRawImage: false,
            idCardImageUrl: "",
            showModalUploadIdCardOwner: false,
            base64IdCardOwner: "",
            familyCardImageUrl: "",
            showModalUploadFamilyCard: false,
            base64FamilyCard: "",
            showDrawerMenu: false,
            idCardNumber: "",
            idCardName: "",
            birthPlace: "",
            birthDate: dayjs("2022-01-01", 'YYYY-MM-DD'),
            fullAddress: "",
            provinceCode: null,
            provinceName: null,
            cityCode: null,
            cityName: null,
            districtCode: null,
            districtName: null,
            subdistrictCode: null,
            subdistrictName: null,
            postalCode: "",
            hamlet: "",
            neighbourhood: "",
            familyCardNumber: "",
            fullAddressCurrent: "",
            provinceCodeCurrent: null,
            provinceNameCurrent: null,
            cityCodeCurrent: null,
            cityNameCurrent: null,
            districtCodeCurrent: null,
            districtNameCurrent: null,
            subdistrictCodeCurrent: null,
            subdistrictNameCurrent: null,
            postalCodeCurrent: "",
            hamletCurrent: "",
            neighbourhoodCurrent: "",
        }
    }

    onFieldChange = <T extends keyof Pick<any, "idCardNumber" | "idCardName" | "birthPlace" | "birthDate" | "fullAddress" | "postalCode" | "hamlet" | "neighbourhood" | "familyCardNumber" | "fullAddressCurrent" | "postalCodeCurrent" | "hamletCurrent" | "neighbourhoodCurrent">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "idCardName":
                currentState.idCardName = value;
                break;
            case "birthPlace":
                currentState.birthPlace = value;
                break;
            case "birthDate":
                if (value) {
                    currentState.birthDate = value;
                }
                break;
            case "fullAddress":
                currentState.fullAddress = value;
                break;
            case "postalCode":
                currentState.postalCode = value;
                break;
            case "hamlet":
                currentState.hamlet = value;
                break;
            case "neighbourhood":
                currentState.neighbourhood = value;
                break;
            case "familyCardNumber":
                currentState.familyCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "fullAddressCurrent":
                currentState.fullAddressCurrent = value;
                break;
            case "postalCodeCurrent":
                currentState.postalCodeCurrent = value;
                break;
            case "hamletCurrent":
                currentState.hamletCurrent = value;
                break;
            case "neighbourhoodCurrent":
                currentState.neighbourhoodCurrent = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    onUpdateFamilyCard = async () => {
        if (!this.state.familyCardImageUrl || !this.state.familyCardNumber) {
            Modal.error({title: 'Error', content: "Mohon lengkapi data"});
            return;
        }
        if (this.state.familyCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KK tidak valid"});
            return;
        }

        const dataUpdate = {
            type: "update-biodata-family-register",
            other_documents: {
                document_image: this.state.familyCardImageUrl,
                document_number: this.state.familyCardNumber,
                type: "family_register"
            }
        }

        await this.promisedSetState({
            submitting: true,
        })

        await authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            this.setState({
                submitting: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    clickUpdateFamilyCardImage = () => {
        if (this.state.familyCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KK tidak valid"});
            return;
        }
        this.setState({showModalUploadFamilyCard: true});
    }

    onCropFamilyCard = async (base64DataImagePng: any) => {
        await this.setState({
            base64FamilyCard: base64DataImagePng,
        });
        window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }

    async componentDidMount() {
        this.setState({
            processing: true,
        });

        try {
            await authApiService.setToken(this.state.token);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;
            const dataIdCard = dataBio?.id_card;
            const dataAddress = dataBio?.address;
            const dataCurrentAddress = dataBio?.current_address;
            const dataFamilyCard = (dataBio?.other_documents ?? []).find((o: any) => o.type === 'family_register') ?? {};

            this.setState({
                processing: false,
                profileData: dataBio,
                idCardImageUrl: (dataIdCard?.id_card_image) ? dataIdCard?.id_card_image : "",
                familyCardImageUrl: (dataFamilyCard?.document_image) ? dataFamilyCard?.document_image : "",
                idCardNumber: (dataIdCard?.id_card_number) ? dataIdCard?.id_card_number : "",
                idCardName: (dataIdCard?.full_name) ? dataIdCard?.full_name : "",
                birthPlace: (dataIdCard?.birth_place) ? dataIdCard?.birth_place : "",
                birthDate: (dataIdCard?.birth_date) ? dayjs((dataIdCard?.birth_date), 'YYYY-MM-DD') : dayjs("2022-01-01", 'YYYY-MM-DD'),
                fullAddress: (dataAddress?.full_address) ? dataAddress?.full_address : "",
                provinceCode: (dataAddress?.province_code) ? dataAddress?.province_code : null,
                provinceName: (dataAddress?.province_name) ? dataAddress?.province_name : null,
                cityCode: (dataAddress?.city_code) ? dataAddress?.city_code : null,
                cityName: (dataAddress?.city_name) ? dataAddress?.city_name : null,
                districtCode: (dataAddress?.district_code) ? dataAddress?.district_code : null,
                districtName: (dataAddress?.district_name) ? dataAddress?.district_name : null,
                subdistrictCode: (dataAddress?.sub_district_code) ? dataAddress?.sub_district_code : null,
                subdistrictName: (dataAddress?.sub_district_name) ? dataAddress?.sub_district_name : null,
                postalCode: (dataAddress?.postal_code) ? dataAddress?.postal_code : null,
                hamlet: (dataAddress?.hamlet) ? dataAddress?.hamlet : null,
                neighbourhood: (dataAddress?.neighbourhood) ? dataAddress?.neighbourhood : null,
                fullAddressCurrent: (dataCurrentAddress?.full_address) ? dataCurrentAddress?.full_address : "",
                provinceCodeCurrent: (dataCurrentAddress?.province_code) ? dataCurrentAddress?.province_code : null,
                provinceNameCurrent: (dataCurrentAddress?.province_name) ? dataCurrentAddress?.province_name : null,
                cityCodeCurrent: (dataCurrentAddress?.city_code) ? dataCurrentAddress?.city_code : null,
                cityNameCurrent: (dataCurrentAddress?.city_name) ? dataCurrentAddress?.city_name : null,
                districtCodeCurrent: (dataCurrentAddress?.district_code) ? dataCurrentAddress?.district_code : null,
                districtNameCurrent: (dataCurrentAddress?.district_name) ? dataCurrentAddress?.district_name : null,
                subdistrictCodeCurrent: (dataCurrentAddress?.sub_district_code) ? dataCurrentAddress?.sub_district_code : null,
                subdistrictNameCurrent: (dataCurrentAddress?.sub_district_name) ? dataCurrentAddress?.sub_district_name : null,
                postalCodeCurrent: (dataCurrentAddress?.postal_code) ? dataCurrentAddress?.postal_code : null,
                hamletCurrent: (dataCurrentAddress?.hamlet) ? dataCurrentAddress?.hamlet : null,
                neighbourhoodCurrent: (dataCurrentAddress?.neighbourhood) ? dataCurrentAddress?.neighbourhood : null,
                familyCardNumber: (dataFamilyCard?.document_number) ? dataFamilyCard?.document_number : "",
            });
        } catch (e) {
            console.log(e);
            this.setState({
                processing: false,
            });
        }
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "FAMILYCARD_IMAGE") {
            if (!this.state.base64FamilyCard) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KK"});
                return;
            }
        }
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }

        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "FAMILYCARD_IMAGE") {
                    await this.promisedSetState({
                        familyCardImageUrl: successData?.data?.data?.url_document,
                    });
                }
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        idCardImageUrl: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadFamilyCard: false,
                    showModalUploadIdCardOwner: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    saveFamilyCardData = async () => {
        let randomString = (Math.random() + 1).toString(36).substring(7);
        const dataUpload = {
            id_image: "amartavip-familycard-" + randomString + "-" + this.state.familyCardNumber,
            image_data: this.state.base64FamilyCard,
        }

        await this.uploadImageAndUpdateState("FAMILYCARD_IMAGE", dataUpload);
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Nomor KK *</i>
                    <Input
                        maxLength={16}
                        value={this.state.familyCardNumber}
                        placeholder="Nomor Sesuai KK"
                        onChange={event => this.onFieldChange("familyCardNumber", event.target.value)}
                    />
                </Form.Item>
                <div className={`card-box-shadow`}>
                    <Row>
                        <Col span={24}>
                            <IdcardOutlined className={`icon-title-info`}/>
                            <b className={`text-title-info`}>Kartu Keluarga</b>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: 10}}>
                        <Col span={23} offset={1}>
                            <Row>
                                <Col span={12}>
                                    <div className="font-old-grey-100">
                                        <i className={`text-extra-small-grey-nopadding`}>Nomor KK:</i>
                                        <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.familyCardNumber} </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    {this.state.familyCardImageUrl
                                        ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.familyCardImageUrl}/></div>
                                        : <div onClick={() => this.clickUpdateFamilyCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                            <div onClick={() => this.clickUpdateFamilyCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                {this.state.familyCardImageUrl
                                    ? <span>Update Gambar Kartu Keluarga</span>
                                    : <span>Lengkapi Gambar Kartu Keluarga</span>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <br/>

                <Button loading={this.state.submitting} onClick={() => this.onUpdateFamilyCard()} type="primary"> Update Data KK</Button>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title="Lengkapi Data KK"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadFamilyCard}
                    onCancel={() => this.setState({showModalUploadFamilyCard: false})}
                    footer={[
                        <Button key={'saveCustomer'} type="primary" onClick={this.saveFamilyCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadFamilyCard: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical">
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64FamilyCard) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 25}}>
                                    <li>Klik Tombol Upload Gambar KK</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropFamilyCard}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default BiodataFamily;

import React, {Component} from 'react';
import {HomeStates} from "./types/HomeTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import HomeBanner from "../../component/pageSection/HomeBanner";
import {Row} from "antd";
import Col from "antd/lib/col";
import FooterSection from "../../component/pageSection/FooterSection";

class Home extends Component<any, HomeStates> {
    private readonly initState!: HomeStates;

    constructor(props: any) {
        super(props);
        document.title = "Trimitra";

        this.initState = {
            fetching: true,
            dataListVacancy: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            fetching: true,
        });
    }

    scrDown = async () => {
        window.scrollTo({top: 900, behavior: 'smooth'});
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {

        const listImage = [{
            key: "STRATEGY",
            image: "1",
        }, {
            key: "CREATIVE",
            image: "2",
        }, {
            key: "PAID MEDIA",
            image: "3",
        }, {
            key: "INFLUENCER",
            image: "4",
        }, {
            key: "PRODUCTION",
            image: "5",
        }, {
            key: "DATA ANALYSIS",
            image: "6",
        }]

        return (
            <React.Fragment>
                <PageHeader/>
                <HomeBanner/>
                <div style={{color: "#fff", fontSize: "250%", paddingTop: 60, backgroundColor: "#282828", textAlign: 'center'}}><b>WHAT WE DO</b></div>
                <Row style={{paddingTop: 40, paddingBottom: 100, backgroundColor: "#282828"}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row justify="center">
                            {listImage.map((item: any, x: number) =>
                                <Col key={x} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}} style={{textAlign: 'center', padding: 20}}>
                                    <div style={{color: "#fff", fontSize: "200%", paddingBottom: 20}}>{item.key}</div>
                                    <img alt="logo" style={{width: "180px"}} src={`../assets/img/${item.image}.png`}/>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default withRouter(Home)
import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {Provider} from "react-redux"
import {store} from './redux'
import Home from "./screen/home/Home"
import FormB2b from "./screen/b2b/FormB2b"
import MyProfile from "./screen/myProfile/MyProfile"
import MyBiodata from "./screen/myProfile/MyBiodata"
import MySubmission from "./screen/myProfile/MySubmission"
import NotFound from "./screen/home/NotFound"

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path={"/my-profile"} caseSensitive={false} element={<MyProfile/>}/>
                    <Route path={"/my-biodata"} caseSensitive={false} element={<MyBiodata/>}/>
                    <Route path={"/my-submission"} caseSensitive={false} element={<MySubmission/>}/>
                    <Route path="/b2b" caseSensitive={false} element={<FormB2b/>}/>
                    <Route path="/" caseSensitive={false} element={<Home/>}/>
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </Router>
        </Provider>
    )
}

export default App
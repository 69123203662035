export const masterBank = [
    {
        "bank_name": "Bank BCA",
        "bank_organization_name": "PT BANK CENTRAL ASIA Tbk",
        "bank_code": "014"
    },
    {
        "bank_name": "Bank Mandiri",
        "bank_organization_name": "PT BANK MANDIRI (PERSERO) Tbk",
        "bank_code": "008"
    },
    {
        "bank_name": "Bank BRI",
        "bank_organization_name": "PT BANK RAKYAT INDONESIA (PERSERO) Tbk",
        "bank_code": "002"
    },
    {
        "bank_name": "Bank BNI",
        "bank_organization_name": "PT BANK NEGARA INDONESIA (PERSERO) Tbk",
        "bank_code": "009"
    },
    {
        "bank_name": "Bank BTN",
        "bank_organization_name": "PT BANK TABUNGAN NEGARA (PERSERO) Tbk",
        "bank_code": "200"
    },
    {
        "bank_name": "Bank BTPN",
        "bank_organization_name": "PT BANK BTPN Tbk",
        "bank_code": "213"
    },
    {
        "bank_name": "Bank BCA Syariah",
        "bank_organization_name": "PT BANK BCA SYARIAH",
        "bank_code": "536"
    },
    {
        "bank_name": "Allo Bank",
        "bank_organization_name": "PT ALLO BANK INDONESIA Tbk",
        "bank_code": "567"
    },
    {
        "bank_name": "Amar Bank",
        "bank_organization_name": "PT BANK AMAR INDONESIA",
        "bank_code": "531"
    },
    {
        "bank_name": "Bank Aceh Syariah",
        "bank_organization_name": "PT BANK ACEH SYARIAH",
        "bank_code": "116"
    },
    {
        "bank_name": "Bank Artha Graha Internasional",
        "bank_organization_name": "PT BANK ARTHA GRAHA INTERNASIONAL Tbk",
        "bank_code": "037"
    },
    {
        "bank_name": "Bank Banten",
        "bank_organization_name": "PT BPD BANTEN Tbk",
        "bank_code": "137"
    },
    {
        "bank_name": "Bank BJB",
        "bank_organization_name": "PT BPD JAWA BARAT DAN BANTEN Tbk",
        "bank_code": "110"
    },
    {
        "bank_name": "Bank BJB Syariah",
        "bank_organization_name": "PT BANK JABAR BANTEN SYARIAH",
        "bank_code": "425"
    },
    {
        "bank_name": "Bank BPD Bali",
        "bank_organization_name": "PT BPD BALI",
        "bank_code": "129"
    },
    {
        "bank_name": "Bank BPD DIY",
        "bank_organization_name": "PT BPD DAERAH ISTIMEWA YOGYAKARTA",
        "bank_code": "112"
    },
    {
        "bank_name": "Bank BTPN Syariah",
        "bank_organization_name": "PT BANK BTPN SYARIAH Tbk",
        "bank_code": "547"
    },
    {
        "bank_name": "Bank Bumi Arta",
        "bank_organization_name": "PT BANK BUMI ARTA Tbk",
        "bank_code": "076"
    },
    {
        "bank_name": "Bank Capital Indonesia",
        "bank_organization_name": "PT BANK CAPITAL INDONESIA Tbk",
        "bank_code": "054"
    },
    {
        "bank_name": "Bank CCB Indonesia",
        "bank_organization_name": "PT BANK CHINA CONSTRUCTION BANK INDONESIA Tbk",
        "bank_code": "036"
    },
    {
        "bank_name": "Bank CIMB Niaga",
        "bank_organization_name": "PT BANK CIMB NIAGA Tbk",
        "bank_code": "022"
    },
    {
        "bank_name": "Bank Commonwealth",
        "bank_organization_name": "PT BANK COMMONWEALTH",
        "bank_code": "950"
    },
    {
        "bank_name": "Bank CTBC Indonesia",
        "bank_organization_name": "PT BANK CTBC INDONESIA",
        "bank_code": "949"
    },
    {
        "bank_name": "Bank Danamon",
        "bank_organization_name": "PT BANK DANAMON INDONESIA Tbk",
        "bank_code": "011"
    },
    {
        "bank_name": "Bank DBS Indonesia",
        "bank_organization_name": "PT BANK DBS INDONESIA",
        "bank_code": "046"
    },
    {
        "bank_name": "Bank Digital BCA",
        "bank_organization_name": "PT BANK DIGITAL BCA",
        "bank_code": "501"
    },
    {
        "bank_name": "Bank DKI",
        "bank_organization_name": "PT BPD DKI",
        "bank_code": "111"
    },
    {
        "bank_name": "Bank HSBC Indonesia",
        "bank_organization_name": "PT BANK HSBC INDONESIA",
        "bank_code": "087"
    },
    {
        "bank_name": "Bank Ina Perdana",
        "bank_organization_name": "PT BANK INA PERDANA Tbk",
        "bank_code": "513"
    },
    {
        "bank_name": "Bank Index Selindo",
        "bank_organization_name": "PT BANK INDEX SELINDO",
        "bank_code": "555"
    },
    {
        "bank_name": "Bank Jasa Jakarta",
        "bank_organization_name": "PT BANK JASA JAKARTA",
        "bank_code": "472"
    },
    {
        "bank_name": "Bank Jateng",
        "bank_organization_name": "PT BPD JAWA TENGAH",
        "bank_code": "113"
    },
    {
        "bank_name": "Bank Jatim",
        "bank_organization_name": "PT BPD JAWA TIMUR Tbk",
        "bank_code": "114"
    },
    {
        "bank_name": "Bank Jtrust Indonesia",
        "bank_organization_name": "PT BANK JTRUST INDONESIA Tbk",
        "bank_code": "095"
    },
    {
        "bank_name": "Bank Kalbar",
        "bank_organization_name": "PT BPD KALIMANTAN BARAT",
        "bank_code": "123"
    },
    {
        "bank_name": "Bank Kalteng",
        "bank_organization_name": "PT BPD KALIMANTAN TENGAH",
        "bank_code": "125"
    },
    {
        "bank_name": "Bank Kaltimtara",
        "bank_organization_name": "PT BPD KALIMANTAN TIMUR DAN KALIMANTAN UTARA",
        "bank_code": "124"
    },
    {
        "bank_name": "Bank KB Bukopin",
        "bank_organization_name": "PT BANK KB BUKOPIN Tbk",
        "bank_code": "441"
    },
    {
        "bank_name": "Bank KEB Hana Indonesia",
        "bank_organization_name": "PT BANK KEB HANA INDONESIA",
        "bank_code": "484"
    },
    {
        "bank_name": "Bank Maluku Malut",
        "bank_organization_name": "PT BPD MALUKU DAN MALUKU UTARA",
        "bank_code": "131"
    },
    {
        "bank_name": "Bank Maspion",
        "bank_organization_name": "PT BANK MASPION INDONESIA Tbk",
        "bank_code": "157"
    },
    {
        "bank_name": "Bank Mayapada",
        "bank_organization_name": "PT BANK MAYAPADA INTERNATIONAL Tbk",
        "bank_code": "097"
    },
    {
        "bank_name": "Bank Maybank Indonesia",
        "bank_organization_name": "PT BANK MAYBANK INDONESIA Tbk",
        "bank_code": "016"
    },
    {
        "bank_name": "Bank Mega",
        "bank_organization_name": "PT BANK MEGA Tbk",
        "bank_code": "426"
    },
    {
        "bank_name": "Bank Mega Syariah",
        "bank_organization_name": "PT BANK MEGA SYARIAH",
        "bank_code": "506"
    },
    {
        "bank_name": "Bank Mestika",
        "bank_organization_name": "PT BANK MESTIKA DHARMA Tbk",
        "bank_code": "151"
    },
    {
        "bank_name": "Bank MNC",
        "bank_organization_name": "PT BANK MNC INTERNASIONAL Tbk",
        "bank_code": "485"
    },
    {
        "bank_name": "Bank Muamalat",
        "bank_organization_name": "PT BANK MUAMALAT INDONESIA Tbk",
        "bank_code": "147"
    },
    {
        "bank_name": "Bank Multiarta Sentosa",
        "bank_organization_name": "PT BANK MULTIARTA SENTOSA Tbk",
        "bank_code": "548"
    },
    {
        "bank_name": "Bank Nagari",
        "bank_organization_name": "PT BANK NAGARI",
        "bank_code": "118"
    },
    {
        "bank_name": "Bank National Nobu",
        "bank_organization_name": "PT BANK NATIONALNOBU Tbk",
        "bank_code": "503"
    },
    {
        "bank_name": "Bank Neo Commerce",
        "bank_organization_name": "PT BANK NEO COMMERCE Tbk",
        "bank_code": "490"
    },
    {
        "bank_name": "Bank OCBC NISP",
        "bank_organization_name": "PT BANK OCBC NISP Tbk",
        "bank_code": "028"
    },
    {
        "bank_name": "Bank of China Jakarta Branch",
        "bank_organization_name": "BANK OF CHINA (HONG KONG) LIMITED CABANG JAKARTA",
        "bank_code": "069"
    },
    {
        "bank_name": "Bank Panin",
        "bank_organization_name": "PT PAN INDONESIA BANK Tbk",
        "bank_code": "019"
    },
    {
        "bank_name": "Bank Papua",
        "bank_organization_name": "PT BPD PAPUA",
        "bank_code": "132"
    },
    {
        "bank_name": "Bank Permata",
        "bank_organization_name": "PT BANK PERMATA Tbk",
        "bank_code": "013"
    },
    {
        "bank_name": "Bank QNB Indonesia",
        "bank_organization_name": "PT BANK QNB INDONESIA Tbk",
        "bank_code": "167"
    },
    {
        "bank_name": "Bank Riau Kepri",
        "bank_organization_name": "PT BPD RIAU KEPRI SYARIAH",
        "bank_code": "119"
    },
    {
        "bank_name": "Bank Sahabat Sampoerna",
        "bank_organization_name": "PT BANK SAHABAT SAMPOERNA",
        "bank_code": "523"
    },
    {
        "bank_name": "Bank SBI Indonesia",
        "bank_organization_name": "PT BANK SBI INDONESIA",
        "bank_code": "498"
    },
    {
        "bank_name": "Bank Shinhan",
        "bank_organization_name": "PT BANK SHINHAN INDONESIA",
        "bank_code": "152"
    },
    {
        "bank_name": "Bank Sinarmas",
        "bank_organization_name": "PT BANK SINARMAS Tbk",
        "bank_code": "153"
    },
    {
        "bank_name": "Bank Sulselbar",
        "bank_organization_name": "PT BPD SULAWESI SELATAN DAN SULAWESI BARAT",
        "bank_code": "126"
    },
    {
        "bank_name": "Bank Sumselbabel",
        "bank_organization_name": "PT BPD SUMATERA SELATAN DAN BANGKA BELITUNG",
        "bank_code": "120"
    },
    {
        "bank_name": "Bank Sumut",
        "bank_organization_name": "PT BPD SUMATERA UTARA",
        "bank_code": "117"
    },
    {
        "bank_name": "Bank Syariah Bukopin",
        "bank_organization_name": "PT BANK KB BUKOPIN SYARIAH",
        "bank_code": "521"
    },
    {
        "bank_name": "Bank Syariah Indonesia",
        "bank_organization_name": "PT BANK SYARIAH INDONESIA Tbk *)",
        "bank_code": "451"
    },
    {
        "bank_name": "Bank UOB Indonesia",
        "bank_organization_name": "PT BANK UOB INDONESIA",
        "bank_code": "023"
    },
    {
        "bank_name": "Bank Victoria",
        "bank_organization_name": "PT BANK VICTORIA INTERNATIONAL Tbk",
        "bank_code": "566"
    },
    {
        "bank_name": "Bank Victoria Syariah",
        "bank_organization_name": "PT BANK VICTORIA SYARIAH",
        "bank_code": "405"
    },
    {
        "bank_name": "Bank Woori Saudara",
        "bank_organization_name": "PT BANK WOORI SAUDARA INDONESIA 1906 Tbk",
        "bank_code": "212"
    },
    {
        "bank_name": "Citibank Indonesia",
        "bank_organization_name": "CITIBANK N.A.",
        "bank_code": "031"
    },
    {
        "bank_name": "IBK Bank Indonesia",
        "bank_organization_name": "PT BANK IBK INDONESIA Tbk",
        "bank_code": "945"
    },
    {
        "bank_name": "MUFG Bank",
        "bank_organization_name": "MUFG BANK LTD",
        "bank_code": "042"
    },
    {
        "bank_name": "Panin Dubai Syariah Bank",
        "bank_organization_name": "PT BANK PANIN DUBAI SYARIAH Tbk",
        "bank_code": "517"
    },
    {
        "bank_name": "Prima Master Bank",
        "bank_organization_name": "PT PRIMA MASTER BANK",
        "bank_code": "520"
    },
    {
        "bank_name": "Standard Chartered Bank",
        "bank_organization_name": "STANDARD CHARTERED BANK",
        "bank_code": "050"
    }
];
import React, {Component} from "react";
import Cookies from 'universal-cookie';
import {Buffer} from "buffer";
import {Button, Card, Col, Divider, Empty, Form, Input, Modal, Row, Spin, Tag, Tooltip, Upload} from "antd";
import {DeleteOutlined, PlusCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import Select from "antd/lib/select";
import CheckCircleTwoTone from "@ant-design/icons/CheckCircleTwoTone";
import {masterBank} from "../../config/appDataConfig/masterBank";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import {ocrApiService} from "../../services/trimitraCdn/OcrApiService";

class BiodataBankAccount extends Component<any, any> {
    private cookies = new Cookies();


    constructor(props: any) {
        super(props);
        this.state = {
            uId: Buffer.from(this.cookies.get('_tc-ui'), 'base64').toString() ?? "",
            fetching: false,
            submitting: false,
            token: this.cookies.get('_tc-t'),
            dataBankAccounts: [],
            userAccountNumber: "",
            userAccountName: "",
            bankCode: null,

            idDelete: 0,
            modalDeleteConfirm: false,
            freezeStatus: false,
            showUploadList: false,
            bankAccountImageUrl: "",

            updateAccountNumber: "",
            modalConfirmUpdateDefaultBank: false,
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "userAccountNumber" | "userAccountName" | "bankCode">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "userAccountNumber":
                currentState.userAccountNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "userAccountName":
                currentState.userAccountName = value?.toUpperCase();
                break;
            case "bankCode":
                currentState.bankCode = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    addBankAccount = async () => {
        this.setState({
            formBankAccountModal: true,
            bankAccountImageUrl: "",
            userAccountNumber: "",
            userAccountName: "",
            bankCode: null,
            showUploadList: false,
        })
    }

    confirmAddData = async () => {
        const pickedBank = masterBank.find((o: { bank_code: string; }) => o.bank_code === this.state.bankCode);

        if (!this.state.userAccountNumber || !this.state.userAccountName || !this.state.bankCode) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Mohon lengkapi data'
            });
            return;
        }

        // if (!this.state.bankAccountImageUrl) {
        //     Modal.error({
        //         title: 'Proses Gagal',
        //         content: 'Mohon upload image rekening koran'
        //     });
        //     return;
        // }

        const picked = this.state.dataBankAccounts.find((o: { account_number: string; }) => o.account_number === this.state.userAccountNumber);
        if (picked) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'duplicate account, silakan hapus account yang sama, terlebih dahulu'
            });
            return;
        }

        await this.promisedSetState({
            submitting: true,
        });

        let ocrResult = {
            result: {
                bank_account_name: "",
                bank_account_number: ""
            },
            payload: null
        };
        let isBankAccountVerified = false;

        /*if (this.state.bankAccountImageUrl) {
            try {
                const responseOcr = await ocrApiService.getImageIdCardNumber({image_url: this.state.bankAccountImageUrl, bank_account_number: this.state.userAccountNumber, bank_account_name: this.state.userAccountName});
                ocrResult = {
                    result: {
                        bank_account_name: responseOcr.data.data.bank_account_name,
                        bank_account_number: responseOcr.data.data.bank_account_number
                    },
                    payload: responseOcr.data.data.payload
                }

                if (responseOcr.data.data.bank_account_name === this.state.userAccountName && responseOcr.data.data.bank_account_number === this.state.userAccountNumber) {
                    isBankAccountVerified = true;
                }
            } catch (e) {
                console.log(e);
            }
        }*/

        if (this.state.bankAccountImageUrl) {
            try {
                const ocrPromise = ocrApiService.getImageIdCardNumber({image_url: this.state.bankAccountImageUrl, bank_account_number: this.state.userAccountNumber, bank_account_name: this.state.userAccountName});

                // create promise 30s
                const timeoutPromise = new Promise((resolve, reject) => {
                    setTimeout(() => {
                        reject(new Error("OCR operation timed out"));
                    }, 30000); // 30 detik
                });

                // Menjalankan promise race antara ocrPromise dan timeoutPromise
                const responseOcr: any = await Promise.race([ocrPromise, timeoutPromise]);
                ocrResult = {
                    result: {
                        bank_account_name: responseOcr.data.data.bank_account_name,
                        bank_account_number: responseOcr.data.data.bank_account_number
                    },
                    payload: responseOcr.data.data.payload
                }
                if (responseOcr.data.data.bank_account_name === this.state.userAccountName && responseOcr.data.data.bank_account_number === this.state.userAccountNumber) {
                    isBankAccountVerified = true;
                }
            } catch (e) {
                console.log(e); // Akan mencetak pesan kesalahan jika ada masalah dalam OCR atau jika waktu habis
            }
        }

        const dataBankAccounts = [...this.state.dataBankAccounts];
        dataBankAccounts.push({
            account_number: this.state.userAccountNumber,
            account_name: this.state.userAccountName,
            bank_code: pickedBank?.bank_code,
            bank_organization_name: pickedBank?.bank_organization_name,
            bank_name: pickedBank?.bank_name,
            url_image: (this.state.bankAccountImageUrl) ? this.state.bankAccountImageUrl : "https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/vip-amarta.png",
            ocr_result: ocrResult,
            fraud: false,
            verified: isBankAccountVerified
        });

        await this.promisedSetState({
            formBankAccountModal: false,
            dataBankAccounts: dataBankAccounts,
        });

        await this.onUpdateBankAccount();
    }

    removeEntryCustom = async (i: number) => {
        const dataBankAccounts = [...this.state.dataBankAccounts];
        const newArray = [];
        let x = 0;
        for (const element of dataBankAccounts) {
            if (i !== x) {
                newArray.push(element);
            }
            x++;
        }
        await this.promisedSetState({
            dataBankAccounts: newArray
        });

        await this.onUpdateBankAccount();
    }

    onUpdateBankAccount = async () => {
        if (this.state.dataBankAccounts.length < 1) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'mohon lengkapi minimal 1 Rekening Bank'
            });
            await this.promisedSetState({
                submitting: false,
            });
            return;
        }

        await this.promisedSetState({
            submitting: true,
        });

        const dataUpdate = {
            type: "update-biodata-bankaccount",
            bank_accounts: this.state.dataBankAccounts
        }

        await authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            this.setState({
                submitting: false,
                modalDeleteConfirm: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    async componentDidMount() {
        this.setState({
            fetching: true,
        });

        try {
            await authApiService.setToken(this.state.token);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;

            this.setState({
                fetching: false,
                dataBankAccounts: (dataBio?.bank_accounts) ? dataBio?.bank_accounts : [],
            });
        } catch (e) {
            console.log(e);
            this.setState({
                fetching: false,
            });
        }
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                freezeStatus: false,
                bankAccountImageUrl: newImgLocation,
                showUploadList: true
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                bankAccountImageUrl: "",
            });
        }
    }

    beforeUpload = (file: any) => {
        if (!this.state.userAccountNumber || !this.state.userAccountName || !this.state.bankCode) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Mohon lengkapi data'
            });
            this.setState({
                showUploadList: false,
            })
            return false;
        }

        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            Modal.error({
                title: 'update Failed',
                content: 'mohon upload file dengan tipe image ',
            });
            this.setState({
                showUploadList: false,
            })
        }
        return isImage;
    }

    handleOkDefaultAccount = async () => {
        const dataUpdate = {
            type: "update-biodata-defaultbankaccount",
            uid: this.state.uId,
            account_number: this.state.updateAccountNumber,
        }

        await authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            setTimeout(function () {
                window.location.reload();
            }, 1000);
            this.setState({
                submitting: false,
                modalConfirmUpdateDefaultBank: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Button loading={this.state.submitting} onClick={event => this.addBankAccount()} type="primary" icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Tambah Rekening Bank</Button>

                <Divider orientation="left" plain style={{marginTop: 40}}>
                    <span className={`divider-stock-purchase`}>Data Rekening Bank Anda</span>
                </Divider>

                <div style={this.state.fetching ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <div className={`p-5`} style={!this.state.fetching ? {} : {display: 'none'}}>
                    <div style={this.state.dataBankAccounts?.length < 1 ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>
                    <div style={this.state.dataBankAccounts?.length < 1 ? {display: 'none'} : {}}>
                        {this.state.dataBankAccounts.map((item: any, i: number) =>
                            <Card bordered={true} key={i} bodyStyle={{padding: 7}} style={{margin: 7}} hoverable={true}>
                                <Row>
                                    <Col xl={{span: 20}} lg={{span: 20}} md={{span: 21}} sm={{span: 21}} xs={{span: 21}} style={{fontSize: "85%"}}>
                                        <span style={{paddingLeft: 10, fontWeight: 500}}>{item.bank_name}</span> <i style={{fontSize: "80%"}} className={`small-text-grey`}> ({item.bank_organization_name})</i>
                                        <span style={item.verified ? {} : {display: 'none'}}><Tooltip title="Verified Bank Account"> &nbsp; <CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip></span><br/>
                                        <span style={{paddingLeft: 10, color: "gray"}}>{item?.account_number} - {item?.account_name}</span>
                                    </Col>
                                    <Col xl={{span: 4}} lg={{span: 4}} md={{span: 3}} sm={{span: 3}} xs={{span: 3}}>
                                        <Tag style={item.verified && i === 0 ? {marginTop: 7, float: "right"} : {display: 'none'}} color="default">*Akun Utama</Tag>
                                        <Tag style={item.verified && i !== 0 ? {marginTop: 7, float: "right"} : {display: 'none'}} onClick={() => this.setState({updateAccountNumber: item?.account_number, modalConfirmUpdateDefaultBank: true, submitting: false})} color="#108ee9">Jadikan Akun Utama</Tag>
                                        <Button
                                            style={!item.verified ? {marginTop: 3, marginRight: 20, float: "right"} : {display: 'none'}}
                                            loading={this.state.submitting}
                                            onClick={event => this.promisedSetState({modalDeleteConfirm: true})}
                                            danger
                                            type="primary"
                                            icon={<DeleteOutlined/>}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </div>
                </div>

                <Modal
                    open={this.state.formBankAccountModal}
                    title="Input Data Rekening Bank"
                    onCancel={() => this.setState({formBankAccountModal: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitting} type={"primary"} key="1" onClick={this.confirmAddData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({formBankAccountModal: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <Form>
                        <Divider/>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Bank <i style={{color: "red"}}>*</i></i>
                            <Select
                                placeholder="Pilih Bank"
                                showSearch
                                optionFilterProp="children"
                                onChange={value => this.onFieldChange("bankCode", value)}
                                value={this.state.bankCode}
                                style={{width: "100%"}}
                            >
                                {masterBank.map((item: any, i: number) =>
                                    <Select.Option key={i} value={item?.bank_code}>{item?.bank_name} - {item?.bank_organization_name}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Nomor Rekening <i style={{color: "red"}}>*</i></i>
                            <Input value={this.state.userAccountNumber} onChange={event => this.onFieldChange("userAccountNumber", event.target.value)} placeholder="Nomor Rekening"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Nama Pemilik Rekening <i style={{color: "red"}}>*</i></i>
                            <Input value={this.state.userAccountName} onChange={event => this.onFieldChange("userAccountName", event.target.value)} placeholder="Nama Pemilik Rekening"/>
                        </Form.Item>

                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Upload Image Bukti Kepemilikan Rekening</i> <span><Tooltip title="digunakan untuk verifikasi kepemilikan rekening"> <InfoCircleOutlined className={`small-text-grey`}/></Tooltip></span> <br/>
                            <Upload
                                method="POST"
                                action={baseUrlCdnUpload + "/upload-document/document-data"}
                                listType="picture"
                                maxCount={1}
                                data={{
                                    document_name: this.state.userAccountNumber,
                                    document_type: "bank_account",
                                    source: "talent.trimitra.biz"
                                }}
                                name="image"
                                onChange={this.uploadImageChange}
                                beforeUpload={this.beforeUpload}
                                accept="image/*"
                                showUploadList={this.state.showUploadList}
                            > <Button icon={<UploadOutlined/>}>Upload SS Rekening Koran</Button>
                            </Upload>
                        </Form.Item>
                        <Divider/>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.modalDeleteConfirm}
                    title="Konfirmasi Hapus Data"
                    closeIcon={true}
                    footer={[
                        <Button
                            key="1"
                            type="primary"
                            danger
                            loading={this.state.submitting}
                            onClick={event => this.removeEntryCustom(this.state.idDelete)}
                        >Hapus Data</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({modalDeleteConfirm: false})
                        }}>Batal Hapus</Button>
                    ]}
                >
                    <Form layout={"vertical"}>
                        Anda Yakin untuk menghapus data ini?
                    </Form>
                </Modal>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title="Konfirmasi Rubah Default Bank"
                    open={this.state.modalConfirmUpdateDefaultBank}
                    onOk={this.handleOkDefaultAccount}
                    confirmLoading={this.state.submitting}
                    onCancel={() => this.setState({modalConfirmUpdateDefaultBank: false})}
                >
                    <p>Anda yakin akan menjadikan default akun bank ?</p> <br/>
                </Modal>
            </React.Fragment>
        );
    }
}

export default BiodataBankAccount;
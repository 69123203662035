import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";
import Cookies from "universal-cookie";

class AuthApiService {
    private cookies = new Cookies();

    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async login(params: any) {
        try {
            return await this.axios.post<any>('/auth/login', params, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async sendResetPassword(params: any) {
        try {
            return await this.axios.post<any>('/user/send-reset-password', params, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async resetPassword(inputData?: any) {
        try {
            return await this.axios.put<any>('/user/reset-password',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async register(params: any) {
        try {
            return await this.axios.post<any>('/auth/register', params, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async checkJwt() {
        try {
            return await this.axios.get<any>(`/auth/check`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async isExistAmartaVipToken() {
        const c = this.cookies.get('_amh-t');
        return (c) ? c : false;
    }

    public async isExistAmartaVipUserId() {
        const c = this.cookies.get('_amh-ui');
        return (c) ? c : false;
    }

    public async getUserBiodata() {
        try {
            return await this.axios.get<any>(`/profile`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async updateBiodata(inputData?: any) {
        try {
            return await this.axios.put<any>('/profile',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const authApiService = new AuthApiService();

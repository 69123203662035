import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class UserServices {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public async checkBlacklistPhoneNumber(params?: { phoneNumber?: string }) {
        const queries: any = {}
        try {
            return await this.axios.get<any>(`/user/blacklist-phone-number/${params?.phoneNumber}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async sendVerifyAccount(inputData?: any) {
        try {
            return await this.axios.post<any>('/user/send-verify-account',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async verifyAccount(data?: any) {
        try {
            return await this.axios.put<any>('/user/verify-account',
                data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const userServices = new UserServices();

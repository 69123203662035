import React, {Component} from 'react';
import MenuOutlined from "@ant-design/icons/MenuOutlined";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import {Header} from "antd/es/layout/layout";
import {Dropdown, MenuProps} from "antd";
import {Link} from "react-router-dom";

import Cookies from "universal-cookie";

class MyProfileLoginHeader extends Component<any> {
    private cookies = new Cookies();

    logout = async () => {
        const c = this.cookies.get('_tc-t');
        if (c) {
            await this.cookies.remove('_tc-t');
            await this.cookies.remove('_tc-n');
            await this.cookies.remove('_tc-p');
            await this.cookies.remove('_tc-e');
            await this.cookies.remove('_tc-ut');
            await this.cookies.remove('_tc-uc');
            await this.cookies.remove('_tc-ui');
        }

        window.location.href = "/login";
    }

    render() {
        const items: MenuProps['items'] = [
            {
                key: '0',
                label: (<Link to={`/`}>
                    <div style={{minWidth: 160}}>Home</div>
                </Link>),
            },
            {
                key: '1',
                label: (<Link to={`/my-profile`}>
                    <div style={{minWidth: 160}}>Profile</div>
                </Link>),
            },
            {
                key: '2',
                label: (<Link to={`/my-biodata`}>
                    <div style={{minWidth: 160}}>Biodata</div>
                </Link>),
            },
            {
                key: '4',
                label: (<Link to={`/my-submission`}>
                    <div style={{minWidth: 160}}>List Pengajuan</div>
                </Link>),
            },
            {
                type: 'divider',
            },
            {
                key: '7',
                label: (<div onClick={this.logout}>Log Out</div>),
            }
        ];

        return (
            <Header className={`desktop-header-search`} style={{backgroundColor: '#fff'}}>
                <Row>
                    <Col span={16}>
                        <Row>
                            <span>
                                <Dropdown menu={{items}} trigger={['click']} placement="bottomLeft">
                                    <MenuOutlined className={`header-home-top`}/>
                                </Dropdown>
                            </span>
                            <Link to={`/`}>
                                <img style={{height: 28, paddingLeft: 15, marginTop: 17}} src={`/assets/img/icon-logo.png`} alt={`logo`}/>
                            </Link>
                        </Row>
                    </Col>
                    <Col span={8} style={{textAlign: "right"}}>
                        <div style={{fontSize: "120%", color: "#4b4b4b", fontWeight: 500, marginTop: -2, textAlign: "right"}}>{this.props.title?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                    </Col>
                </Row>
            </Header>
        );
    }
}

export default MyProfileLoginHeader;

import axios from "axios"

class BusinessEntityService {
    private axios = axios.create({
        baseURL: "https://asia-southeast2-autotrimitra.cloudfunctions.net",
    })

    public async sendGeneralEntity(inputData?: any) {
        try {
            return await this.axios.post<any>('/api-trimitra-biz__post-businessentities/business-entity/generalentity',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Basic cHVibGljOnB1YmxpYy03ODM3MmRJSzhkNjdxOGFKNzZmZDllSks="
                    }
                })
        } catch (e: any) {
            throw new Error(e.response.data.error.message)
        }
    }
}

export const businessEntityService = new BusinessEntityService()
import React, {Component} from "react"
import './PageHeader.css'
import {Link} from "react-router-dom"

class PageHeader extends Component<any, any> {
    private readonly initState!: any

    constructor(props: any) {
        super(props)

        this.initState = {
            loading: false,
            headerScrollDown: false,
            showMobileMenu: false,
        }
        this.state = {...this.initState}
    }

    onClickMobileLeftMenu = async () => {
        this.setState({
            showMobileMenu: (!this.state.showMobileMenu),
        })
    }

    render() {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 85) {
                this.setState({
                    headerScrollDown: true
                })
            } else {
                this.setState({
                    headerScrollDown: false
                })
            }
        })

        return (
            <React.Fragment>
                <header className={`header headerMenu ${(this.state.headerScrollDown) ? "on-scroll" : ""}`} id="header">
                    <nav className="navbar container">
                        <Link to={`/`}>
                            <div style={{backgroundColor: "rgb(255 255 255 / 58%)", borderRadius: 10, padding: 5}}>
                                <img style={{height: 30}} src={`/assets/img/icon-logo.png`} alt={`logo`}/>
                            </div>
                        </Link>
                        <div className={`burger ${(this.state.showMobileMenu) ? "is-active" : ""}`} id="burger" onClick={this.onClickMobileLeftMenu}>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                        </div>
                        <div className={`menu ${(this.state.showMobileMenu) ? "is-active" : ""}`} id="menu">
                            <ul className="menu-inner">
                                <li className="menu-item" key={1}><a href="/" className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}><b>Home</b></a></li>
                            </ul>
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        )
    }
}

export default PageHeader
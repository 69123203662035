import React, {Component} from "react";
import Cookies from 'universal-cookie';
import {Button, Card, Col, Divider, Empty, Form, Input, Modal, Row, Image, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {authApiService} from "../../services/amartaVip/AuthApiService";

class BiodataSocialMedia extends Component<any, any> {
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.state = {
            fetching: false,
            submitting: false,
            token: this.cookies.get('_tc-t'),
            dataSocialMedia: [],

            idDelete: 0,
            modalDeleteConfirm: false,
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "idCardNumber" | "agentCode" | "tempUserAccount">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "idCardNumber":
                currentState.idCardNumber = value;
                break;
            case "agentCode":
                currentState.agentCode = value;
                break;
            case "tempUserAccount":
                currentState.tempUserAccount = value;
                break;
        }

        await this.promisedSetState({
            ...currentState,
        });
    }

    addUserSosmed = async (media: string) => {
        let urlTarget = "";
        switch (media) {
            case "instagram":
                urlTarget = "https://www.instagram.com/";
                break;
            case "youtube":
                urlTarget = "https://www.youtube.com/@";
                break;
            case "facebook":
                urlTarget = "https://www.facebook.com/";
                break;
            case "twitter":
                urlTarget = "https://twitter.com/";
                break;
            case "tiktok":
                urlTarget = "https://www.tiktok.com/@";
                break;
        }

        this.setState({
            formSocialMediaModal: true,
            tempUserAccount: "",
            tempMediaAccount: media,
            tempUrlMedia: urlTarget,
        })
    }

    confirmAddNewData = async () => {
        if (!this.state.tempUserAccount || !this.state.tempUrlMedia) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Mohon lengkapi data'
            });
            return;
        }

        const picked = this.state.dataSocialMedia.find((o: { media: string; }) => o.media === this.state.tempMediaAccount);
        if (picked) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'duplicate account, silakan hapus media yang sama'
            });
            return;
        }

        const dataSocialMedia = [...this.state.dataSocialMedia];
        dataSocialMedia.push({
            media: this.state.tempMediaAccount,
            user_account: this.state.tempUserAccount?.trim(),
            url: this.state.tempUrlMedia + this.state.tempUserAccount?.trim(),
            active: false,
            public: false,
            verified: false
        });

        await this.promisedSetState({
            formSocialMediaModal: false,
            dataSocialMedia: dataSocialMedia,
        });

        await this.onUpdateSocialMedia();
    }

    removeEntryCustom = async (i: number) => {
        const dataSocialMedia = [...this.state.dataSocialMedia];
        const newArray = [];
        let x = 0;
        for (const element of dataSocialMedia) {
            if (i !== x) {
                newArray.push(element);
            }
            x++;
        }
        await this.promisedSetState({
            dataSocialMedia: newArray
        });

        await this.onUpdateSocialMedia();
    }

    onUpdateSocialMedia = async () => {
        if (this.state.dataSocialMedia.length < 1) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'mohon lengkapi minimal 1 sosial media'
            });
            return;
        }

        await this.promisedSetState({
            submitting: true,
        });

        const dataUpdate = {
            type: "update-biodata-social-media",
            social_media_accounts: this.state.dataSocialMedia
        }

        await authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            this.setState({
                submitting: false,
                modalDeleteConfirm: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    async componentDidMount() {
        this.setState({
            fetching: true,
        });

        try {
            await authApiService.setToken(this.state.token);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;

            this.setState({
                fetching: false,
                dataSocialMedia: (dataBio?.social_media_accounts) ? dataBio?.social_media_accounts : [],
            });
        } catch (e) {
            console.log(e);
            this.setState({
                fetching: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Divider orientation="left" plain>
                    <span className={`divider-stock-purchase`}>Pilih dan lengkapi Akun Sosmed yang anda miliki</span>
                </Divider>

                <Row>
                    <Col xl={{span: 4}} lg={{span: 4}} md={{span: 6}} sm={{span: 6}} xs={{span: 6}} style={{padding: 5}}>
                        <Card
                            onClick={event => this.addUserSosmed("twitter")}
                            hoverable
                            style={{width: "100%"}}
                            bodyStyle={{padding: 1, textAlign: "center"}}
                            cover={<img alt="twitter" src={'../assets/img/twitter.png'}/>}
                        >
                            <span style={{color: "grey", fontSize: "85%"}}>Twitter</span>
                        </Card>
                    </Col>
                    <Col xl={{span: 4}} lg={{span: 4}} md={{span: 6}} sm={{span: 6}} xs={{span: 6}} style={{padding: 5}}>
                        <Card
                            onClick={event => this.addUserSosmed("instagram")}
                            hoverable
                            style={{width: "100%"}}
                            bodyStyle={{padding: 1, textAlign: "center"}}
                            cover={<img alt="instagram" src={'../assets/img/instagram.png'}/>}
                        >
                            <span style={{color: "grey", fontSize: "85%"}}>Instagram</span>
                        </Card>
                    </Col>
                    <Col xl={{span: 4}} lg={{span: 4}} md={{span: 6}} sm={{span: 6}} xs={{span: 6}} style={{padding: 5}}>
                        <Card
                            onClick={event => this.addUserSosmed("facebook")}
                            hoverable
                            style={{width: "100%"}}
                            bodyStyle={{padding: 1, textAlign: "center"}}
                            cover={<img alt="facebook" src={'../assets/img/facebook.png'}/>}
                        >
                            <span style={{color: "grey", fontSize: "85%"}}>Facebook</span>
                        </Card>
                    </Col>
                    <Col xl={{span: 4}} lg={{span: 4}} md={{span: 6}} sm={{span: 6}} xs={{span: 6}} style={{padding: 5}}>
                        <Card
                            onClick={event => this.addUserSosmed("youtube")}
                            hoverable
                            style={{width: "100%"}}
                            bodyStyle={{padding: 1, textAlign: "center"}}
                            cover={<img alt="youtube" src={'../assets/img/youtube.png'}/>}
                        >
                            <span style={{color: "grey", fontSize: "85%"}}>Youtube</span>
                        </Card>
                    </Col>
                    <Col xl={{span: 4}} lg={{span: 4}} md={{span: 6}} sm={{span: 6}} xs={{span: 6}} style={{padding: 5}}>
                        <Card
                            onClick={event => this.addUserSosmed("tiktok")}
                            hoverable
                            style={{width: "100%"}}
                            bodyStyle={{padding: 1, textAlign: "center"}}
                            cover={<img alt="tiktok" src={'../assets/img/tiktok.png'}/>}
                        >
                            <span style={{color: "grey", fontSize: "85%"}}>Tiktok</span>
                        </Card>
                    </Col>
                </Row>

                <Divider orientation="left" plain style={{marginTop: 50}}>
                    <span className={`divider-stock-purchase`}>Data Sosmed Anda</span>
                </Divider>

                <div style={this.state.fetching ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <div className={`p-20`} style={!this.state.fetching ? {} : {display: 'none'}}>
                    <div style={this.state.dataSocialMedia?.length < 1 ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>
                    <div style={this.state.dataSocialMedia?.length < 1 ? {display: 'none'} : {}}>
                        {this.state.dataSocialMedia.map((item: any, i: number) =>
                            <Card bordered={true} key={i} bodyStyle={{padding: 7}} style={{margin: 7}} hoverable={true}>
                                <Row>
                                    <Col xl={{span: 2}} lg={{span: 2}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                                        <Image style={{height: 50}} alt="youtube" src={`../assets/img/${item?.media}.png`}/>
                                    </Col>
                                    <Col xl={{span: 20}} lg={{span: 20}} md={{span: 17}} sm={{span: 17}} xs={{span: 17}} style={{fontSize: "85%"}}>
                                        <i style={{paddingLeft: 10}}>{item?.user_account}</i><br/>
                                        <a href={item?.url} target={`_blank`}>{item?.url}</a>
                                    </Col>
                                    <Col xl={{span: 2}} lg={{span: 2}} md={{span: 3}} sm={{span: 3}} xs={{span: 3}}>
                                        <Button
                                            loading={this.state.submitting}
                                            onClick={event => this.promisedSetState({
                                                idDelete: i,
                                                modalDeleteConfirm: true
                                            })}
                                            style={{marginTop: 10}}
                                            danger
                                            type="primary"
                                            icon={<DeleteOutlined/>}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </div>
                </div>

                <Modal
                    open={this.state.formSocialMediaModal}
                    title="Akun Sosmed"
                    onCancel={() => this.setState({formSocialMediaModal: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button loading={this.state.submitting} type={"primary"} key="1" onClick={this.confirmAddNewData}>Add</Button>,
                        <Button key="2" onClick={() => this.setState({formSocialMediaModal: false})}>Close</Button>,
                    ]}
                >
                    <Form>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Account Name</i>
                            <Input value={this.state.tempUserAccount} onChange={event => this.onFieldChange("tempUserAccount", event.target.value)} placeholder="Account Name"/>
                        </Form.Item>
                        <a href={this.state.tempUrlMedia + this.state.tempUserAccount} target={`_blank`}>{this.state.tempUrlMedia + this.state.tempUserAccount}</a>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.modalDeleteConfirm}
                    title="Konfirmasi Hapus Data"
                    closeIcon={true}
                    footer={[
                        <Button
                            key="1"
                            type="primary"
                            danger
                            loading={this.state.submitting}
                            onClick={event => this.removeEntryCustom(this.state.idDelete)}
                        >Hapus Data</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({modalDeleteConfirm: false})
                        }}>Batal Hapus</Button>
                    ]}
                >
                    <Form layout={"vertical"}>
                        Anda Yakin untuk menghapus data ini?
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default BiodataSocialMedia;
const initialState = {
    popup: 'Doni R',
    isLogin: false,
    user: 'Hiro'
}

export interface Iaction {
    type: string,
    value: any
}

const reducer = (state = initialState, action: Iaction) => {
    if (action.type === 'CHANGE_POPUP') {
        return {
            ...state,
            popup: action.value
        }
    }

    if (action.type === 'CHANGE_ISLOGIN') {
        return {
            ...state,
            popup: action.value
        }
    }

    if (action.type === 'CHANGE_USER') {
        return {
            ...state,
            user: action.value
        }
    }

    return state;
}

export default reducer;

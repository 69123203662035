import React, {Component} from "react";
import Cookies from 'universal-cookie';
import {Button, Card, Col, Divider, Empty, Form, Input, Modal, Row, Spin} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import Select from "antd/lib/select";

class BiodataVehicleLicense extends Component<any, any> {
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.state = {
            fetching: false,
            submitting: false,
            token: this.cookies.get('_tc-t'),
            dataVehicleLicense: [],
            tempLicenseCardNumber: "",
            tempLicenseType: null,

            idDelete: 0,
            modalDeleteConfirm: false,
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "tempLicenseCardNumber" | "tempLicenseType">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "tempLicenseCardNumber":
                currentState.tempLicenseCardNumber = value;
                break;
            case "tempLicenseType":
                currentState.tempLicenseType = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    addUserVehicleLicense = async (media: string) => {
        this.setState({
            formVehicleLicenseModal: true,
            tempLicenseCardNumber: "",
            tempLicenseType: null,
        })
    }

    confirmCustomCampaign = async () => {
        if (!this.state.tempLicenseCardNumber || !this.state.tempLicenseType) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Mohon lengkapi data'
            });
            return;
        }

        const picked = this.state.dataVehicleLicense.find((o: { media: string; }) => o.media === this.state.tempLicenseType);
        if (picked) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'duplicate account, silakan hapus media yang sama'
            });
            return;
        }

        const dataVehicleLicense = [...this.state.dataVehicleLicense];
        dataVehicleLicense.push({
            type: this.state.tempLicenseType,
            document_image: "",
            document_number: this.state.tempLicenseCardNumber,
        });

        await this.promisedSetState({
            formVehicleLicenseModal: false,
            dataVehicleLicense: dataVehicleLicense,
        });

        await this.onUpdateVehicleLicense();
    }

    removeEntryCustom = async (i: number) => {
        const dataVehicleLicense = [...this.state.dataVehicleLicense];
        const newArray = [];
        let x = 0;
        for (const element of dataVehicleLicense) {
            if (i !== x) {
                newArray.push(element);
            }
            x++;
        }
        await this.promisedSetState({
            dataVehicleLicense: newArray
        });

        await this.onUpdateVehicleLicense();
    }

    onUpdateVehicleLicense = async () => {
        if (this.state.dataVehicleLicense.length < 1) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'mohon lengkapi minimal 1 SIM'
            });
            return;
        }

        await this.promisedSetState({
            submitting: true,
        });

        for (const item of this.state.dataVehicleLicense) {
            const dataUpdate = {
                type: "update-biodata-driver-license",
                other_documents: item
            }

            await authApiService.setToken(this.state.token);
            try {
                await authApiService.updateBiodata(dataUpdate);
            } catch (e) {
                console.log(e);
            }
        }

        Modal.success({
            title: 'Proses Sukses',
            content: 'data berhasil di update'
        });
        this.setState({
            submitting: false,
            modalDeleteConfirm: false,
        });
    }

    async componentDidMount() {
        this.setState({
            fetching: true,
        });

        try {
            await authApiService.setToken(this.state.token);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;

            this.setState({
                fetching: false,
                dataVehicleLicense: (dataBio?.other_documents) ? dataBio?.other_documents : [],
            });
        } catch (e) {
            console.log(e);
            this.setState({
                fetching: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Button loading={this.state.submitting} onClick={event => this.addUserVehicleLicense("twitter")} type="primary" icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Tambah Jenis SIM</Button>

                <Divider orientation="left" plain style={{marginTop: 40}}>
                    <span className={`divider-stock-purchase`}>Data SIM Anda</span>
                </Divider>

                <div style={this.state.fetching ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <div className={`p-20`} style={!this.state.fetching ? {} : {display: 'none'}}>
                    <div style={this.state.dataVehicleLicense?.length < 1 ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>
                    <div style={this.state.dataVehicleLicense?.length < 1 ? {display: 'none'} : {}}>
                        {this.state.dataVehicleLicense.map((item: any, i: number) =>
                            <Card bordered={true} key={i} bodyStyle={{padding: 7}} style={(item?.type === "family_register" || item?.type === "cv") ? {display: 'none'} : {margin: 7}} hoverable={true}>
                                <Row>
                                    <Col xl={{span: 22}} lg={{span: 22}} md={{span: 21}} sm={{span: 21}} xs={{span: 21}} style={{fontSize: "85%"}}>
                                        {(() => {
                                            if (item?.type === "driving_license_a") {
                                                return (
                                                    <React.Fragment>
                                                        <span style={{paddingLeft: 10}}>SIM A</span><br/>
                                                    </React.Fragment>
                                                )
                                            } else if (item?.type === "driving_license_c") {
                                                return (
                                                    <React.Fragment>
                                                        <span style={{paddingLeft: 10}}>SIM C</span><br/>
                                                    </React.Fragment>
                                                )
                                            }
                                            if (item?.type === "driving_license_b1") {
                                                return (
                                                    <React.Fragment>
                                                        <span style={{paddingLeft: 10}}>SIM B1</span><br/>
                                                    </React.Fragment>
                                                )
                                            }
                                            if (item?.type === "driving_license_b2") {
                                                return (
                                                    <React.Fragment>
                                                        <span style={{paddingLeft: 10}}>SIM B2</span><br/>
                                                    </React.Fragment>
                                                )
                                            }
                                            if (item?.type === "driving_license_d") {
                                                return (
                                                    <React.Fragment>
                                                        <span style={{paddingLeft: 10}}>SIM D</span><br/>
                                                    </React.Fragment>
                                                )
                                            } else {
                                                return (<span> - </span>)
                                            }
                                        })()}
                                        <span style={{paddingLeft: 10, color: "gray"}}>{item?.document_number}</span>
                                    </Col>
                                    <Col xl={{span: 2}} lg={{span: 2}} md={{span: 3}} sm={{span: 3}} xs={{span: 3}}>
                                        <Button
                                            loading={this.state.submitting}
                                            onClick={event => this.promisedSetState({
                                                idDelete: i,
                                                modalDeleteConfirm: true
                                            })}
                                            style={{marginTop: 10}}
                                            danger
                                            type="primary"
                                            icon={<DeleteOutlined/>}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </div>
                </div>

                <Modal
                    open={this.state.formVehicleLicenseModal}
                    title="Input Data SIM"
                    onCancel={() => this.setState({formVehicleLicenseModal: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmCustomCampaign}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({formVehicleLicenseModal: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <Form>
                        <Divider/>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Jenis SIM</i>
                            <Select
                                placeholder="Pilih Jenis SIM"
                                showSearch
                                optionFilterProp="children"
                                onChange={value => this.onFieldChange("tempLicenseType", value)}
                                value={this.state.tempLicenseType}
                                style={{width: "100%"}}
                            >
                                <Select.Option value="driving_license_a">SIM A</Select.Option>
                                <Select.Option value="driving_license_c">SIM C</Select.Option>
                                <Select.Option value="driving_license_b1">SIM B1</Select.Option>
                                <Select.Option value="driving_license_b2">SIM B2</Select.Option>
                                <Select.Option value="driving_license_d">SIM D</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Nomor SIM</i>
                            <Input value={this.state.tempLicenseCardNumber} onChange={event => this.onFieldChange("tempLicenseCardNumber", event.target.value)} placeholder="Nomor SIM"/>
                        </Form.Item>
                        <Divider/>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.modalDeleteConfirm}
                    title="Konfirmasi Hapus Data"
                    closeIcon={true}
                    footer={[
                        <Button
                            key="1"
                            type="primary"
                            danger
                            loading={this.state.submitting}
                            onClick={event => this.removeEntryCustom(this.state.idDelete)}
                        >Hapus Data</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({modalDeleteConfirm: false})
                        }}>Batal Hapus</Button>
                    ]}
                >
                    <Form layout={"vertical"}>
                        Anda Yakin untuk menghapus data ini?
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default BiodataVehicleLicense;
import React, {Component} from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Image from "antd/lib/image";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Collapse from "antd/lib/collapse";
import CropIdCardImage from "./CropIdCardImage";
import {Upload} from "antd";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import Spin from "antd/lib/spin";
import IdcardOutlined from "@ant-design/icons/IdcardOutlined";

const {Panel} = Collapse;

class UploadIdCardImage extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            submitting: false,
            ownerIdCardNumber: "",
            ownerIdCardName: "",
            ownerIdCardImage: "",
            showModalUploadIdCardOwner: false,
            showOldUploadRawImage: true,
            base64IdCardOwner: "",
        }
        this.state = {...this.initState}
    }

    exportData = async () => {
        this.props.onImageUpdateProps({
            id: this.props.id,
            image_url: this.state.ownerIdCardImage,
        });
    }

    async componentDidMount() {
        this.setState({
            ownerIdCardNumber: this.props.number,
            ownerIdCardName: this.props.name,
        });
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.number !== prevProps.number) || this.props.name !== prevProps.name) {
            this.setState({
                ownerIdCardNumber: this.props.number,
                ownerIdCardName: this.props.name,
            });
        }
    }

    clickUpdateIdCardImage = () => {
        if (this.state.ownerIdCardNumber?.trim()?.length < 5) {
            Modal.error({title: 'Error', content: `Nomor ${this.props.title} tidak valid`});
            return;
        }
        this.setState({showModalUploadIdCardOwner: true});
    }

    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        let randomString = (Math.random() + 1).toString(36).substring(7);
        const dataUpload = {
            id_image: "amartavip-idcard-" + randomString + "-" + this.state.ownerIdCardNumber,
            image_data: this.state.base64IdCardOwner,
        }

        await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload);
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: `Mohon Lengkapi Image ${this.props.title}`});
                return;
            }
        }

        await this.promisedSetState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "FAMILYCARD_IMAGE") {
                    await this.promisedSetState({
                        familyCardImageUrl: successData?.data?.data?.url_document,
                    });
                }
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        ownerIdCardImage: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadFamilyCard: false,
                    showModalUploadIdCardOwner: false,
                });

                await this.exportData();
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.promisedSetState({
                freezeStatus: false
            });
        }
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64IdCardOwner: base64DataImagePng,
        });
    }

    uploadImageChange = async (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            await this.promisedSetState({
                submitting: false,
                ownerIdCardImage: newImgLocation,
            });

            await this.exportData();
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                submitting: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                submitting: false,
                ownerIdCardImage: "",
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div className={`card-box-shadow`}>
                    <Row>
                        <Col span={24}>
                            <IdcardOutlined className={`icon-title-info`}/>
                            <b className={`text-title-info`}>{this.props.title}</b>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: 10}}>
                        <Col span={23} offset={1}>
                            <Row>
                                <Col span={12} style={(this.props.hideInfo) ? {display: 'none'} : {}}>
                                    <div className="font-old-grey-100">
                                        <i className={`text-extra-small-grey-nopadding`}>Nomor {this.props.title}:</i>
                                        <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.ownerIdCardNumber} </div>
                                        <div style={(!this.props.name) ? {display: 'none'} : {}}>
                                            <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.ownerIdCardName} </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    {this.state.ownerIdCardImage
                                        ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}>File Uploaded</div>
                                        : <div onClick={() => this.clickUpdateIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={(this.props.imageSrc) ? this.props.imageSrc : '/assets/img/uploadfile.png'}/></div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                            <div onClick={() => this.clickUpdateIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                {this.state.ownerIdCardImage
                                    ? <span>Update File</span>
                                    : <span>Lengkapi File</span>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title={`Lengkapi Data ${this.props.title}`}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload File {this.props.title}</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                id_card_number: this.state.ownerIdCardNumber,
                                source: "amartahonda.com",
                                notes: "my-biodata"
                            }}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload File</Button>
                        </Upload>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UploadIdCardImage;
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Button from "antd/lib/button";
import Layout from "antd/lib/layout";
import Col from "antd/lib/col";
import ShoppingOutlined from '@ant-design/icons/ShoppingOutlined';
class NotFound extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            areaCode: ""
        }
        this.state = {...this.initState}
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{marginTop: 150, textAlign: "center"}}>
                            <h1>Error 404 Not Found</h1>
                            <br/><br/><br/><br/>
                            <Link to={`/`}>
                                <Button type={`primary`} size={`large`} icon={<ShoppingOutlined/>}>Kembali Ke Halaman Utama</Button>
                            </Link>
                        </div>
                    </Col>
                </Layout>
            </React.Fragment>
        );
    }
}

export default NotFound;

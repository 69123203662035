import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class VacancyApiService {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async getListVacancy() {
        try {
            return await this.axios.get<any>(`/talent/all`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getUserApply() {
        try {
            return await this.axios.get<any>(`/talent/user-apply`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getListDetailVacancy(params: any) {
        try {
            return await this.axios.get<any>(`/talent/${params?.companyCode}?vacancy_code=${params?.vacancyCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }

    }

    public async userApply(params: any) {
        try {
            return await this.axios.post<any>('/talent/user-apply', params, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const vacancyApiService = new VacancyApiService();

/** PRODUCTION LINK */
const baseUrlTrimitraCatalog = "https://zvu1c5uoue.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAmartaVip = "https://3krjkubmjk.execute-api.ap-southeast-3.amazonaws.com/v1";
const baseUrlCdnUpload = "https://hbxhwzeej9.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAutotrimitra = "https://au-api-trimitra-get-65q45htc.ts.gateway.dev";
const baseUrlCdnOrc = "https://asia-southeast1-autotrimitra.cloudfunctions.net/api-cdn-trimitra-biz__ocr-document";

/** KEY */
const xApiKeyTrimitraCatalog = '5ukoYcllpl6lIeKsbeIPI4hOZGDszFVk1dDBddHi';
const xApiKeyAmartaVip = 'wbEBxCen12pHWwBHMakz3ebKWmttdwr9BfYrZlw2';
const xApiKeyCdnUpload = 'zXSa4iZwycS0O5kaoMvi2yAFiIpimTZ55MvvJLy0';

const secretKeyOcr = 'Mn3zrubT3AM5NA8UFXZO6BgV6Rd69YrH';

export {baseUrlCdnOrc, secretKeyOcr, baseUrlAmartaVip, xApiKeyAmartaVip, baseUrlCdnUpload, xApiKeyCdnUpload, baseUrlTrimitraCatalog, xApiKeyTrimitraCatalog, baseUrlAutotrimitra}
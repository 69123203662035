import React, {Component} from "react";
import {Buffer} from "buffer";
import Cookies from 'universal-cookie';
import {Button, Card, Col, Divider, Modal, Row, Spin, Upload} from "antd";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import {baseUrlCdnUpload, xApiKeyCdnUpload} from "../../config/apiConfig/apiConfig";
import UploadOutlined from "@ant-design/icons/UploadOutlined";

class BiodataCv extends Component<any, any> {
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.state = {
            fetching: false,
            submitting: false,
            token: this.cookies.get('_tc-t'),
            uId: Buffer.from(this.cookies.get('_tc-ui'), 'base64').toString() ?? "",
            name: Buffer.from(this.cookies.get('_tc-n'), 'base64').toString(),
            dataCv: {},
            freezeStatus: false,
            docImageUrl: "",
        }
    }

    async fetchData() {
        this.setState({
            fetching: true,
        });
        try {
            await authApiService.setToken(this.state.token);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;
            const dataCv = (dataBio?.other_documents ?? []).find((o: any) => o.type === 'cv') ?? {};

            this.setState({
                fetching: false,
                dataCv: dataCv,
            });
        } catch (e) {
            console.log(e);
            this.setState({
                fetching: false,
            });
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    uploadImageChange = async (data: any) => {
        if (data?.file?.status === "done") {
            const newDocLocation = data?.file?.response?.data?.url_document;
            const dataUpdate = {
                type: "update-biodata-cv",
                other_documents: {
                    document_url: newDocLocation,
                    document_number: "cv_" + this.state.uId,
                    type: "cv"
                }
            }

            await authApiService.setToken(this.state.token);
            try {
                await authApiService.updateBiodata(dataUpdate);
                this.fetchData();
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'data berhasil di update'
                });
                this.setState({
                    submitting: false,
                });
            } catch (e) {
                Modal.error({
                    title: 'update Failed',
                    content: 'error: ' + e,
                });
                this.setState({
                    submitting: false,
                });
            }

            this.setState({
                freezeStatus: false,
                docImageUrl: newDocLocation,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                docImageUrl: "",
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Divider orientation="left" plain>
                    <span className={`divider-stock-purchase`}>CV</span>
                </Divider>
                <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                    <i>*Halaman ini dapat digunakan jika anda inging menyertakan CV yang telah anda buat.</i>
                </div>
                <br/>

                <Card bordered={true} bodyStyle={{padding: 7}} style={this.state.dataCv?.document_url ? {width: "100%", marginTop: 20, marginBottom: 20} : {display: 'none'}}>
                    CV {this.state.name}<br/>
                    <a target={`_blank`} href={this.state.dataCv?.document_url}>Download</a>
                </Card>

                <Row>
                    <Col span={24} style={{padding: 5, marginTop: 20}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/document-data"}
                            maxCount={1}
                            data={{document_name: "cv_" + this.state.uId, document_type: "cv"}}
                            headers={{"x-api-key": xApiKeyCdnUpload}}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload CV</Button>
                        </Upload>
                    </Col>
                </Row>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default BiodataCv;

import React, {Component} from "react";
import './HomeBanner.css'

class HomeBanner extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    scrDown = async () => {
        window.scrollTo({top: 900, behavior: 'smooth'});
    }

    render() {
        return (
            <React.Fragment>
                <div className="img-1">
                    <div className="section section-1">
                        <div style={{fontSize: "450%"}}><b>WE BUILD</b></div>
                        <div style={{fontSize: "260%"}}><b>POWERFUL DIGITAL DEALER</b></div>
                        <div style={{marginTop: 10}}>Using Digital Branding and Communication to empower your dealership.</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HomeBanner;

import React, {Component} from 'react'
import {withRouter} from "../../hoc/withRouter"
import {Breadcrumb, Col, Row, Form, Input, Select, Button, Modal, Divider} from "antd"
import TextArea from "antd/es/input/TextArea"
import AreaProvinceSelect from "../../component/area/AreaProvinceSelect"
import AreaCitySelect from "../../component/area/AreaCitySelect"
import AreaDistrictSelect from "../../component/area/AreaDistrictSelect"
import AreaSubdistrictSelect from "../../component/area/AreaSubdistrictSelect"
import {businessEntityService} from "../../services/autotrimitra/businessEntityService"
import UploadIdCardImage from "../../component/image/UploadIdCardImage";
import UploadFile from "../../component/image/UploadFile";
import reformatPhoneNumber from "../../helper/reformatPhoneNumber"
import {v4 as uuidv4} from 'uuid';

class FormB2b extends Component<any, any> {
    private readonly initState!: any

    constructor(props: any) {
        super(props)
        document.title = "B2B Form"
        const uuid = uuidv4()
        this.initState = {
            uuid: uuid,
            fetching: true,
            submitting: false,

            fullAddress: "",
            provinceCode: null,
            provinceName: null,
            cityCode: null,
            cityName: null,
            districtCode: null,
            districtName: null,
            subdistrictCode: null,
            subdistrictName: null,
            postalCode: "",

            businessEntityType: null,
            businessEntityName: "",
            nib: "",
            npwp: "",
            phoneNumber: "",
            email: "",

            website: "",
            imageNpwp: "",
            pdfNib: "",
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            fetching: true,
        })
    }

    onFieldChange = <T extends keyof Pick<any, "email" | "businessEntityType" | "businessEntityName" | "nib" | "npwp" | "fullAddress" | "postalCode" | "phoneNumber" | "website">>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "website":
                currentState.website = value.toLowerCase().trim()
                break
            case "email":
                currentState.email = value.toLowerCase().trim()
                break
            case "businessEntityType":
                currentState.businessEntityType = value
                break
            case "businessEntityName":
                currentState.businessEntityName = value.toUpperCase()
                break
            case "nib":
                currentState.nib = value
                break
            case "npwp":
                currentState.npwp = value.replace(/[^0-9.]/g, '').replace(".","")
                break
            case "fullAddress":
                currentState.fullAddress = value
                break
            case "postalCode":
                currentState.postalCode = value.replace(/[^0-9.]/g, '')
                break
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '')
                break
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = ""
                }
            }
        }

        this.setState({
            ...currentState,
        })
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            provinceCode: data.value,
            provinceName: data.children,
            cityCode: "",
            cityName: "",
            districtCode: "",
            districtName: "",
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        })
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            cityCode: data.value,
            cityName: data.children,
            districtCode: "",
            districtName: "",
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        })
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            districtCode: data.value,
            districtName: data.children,
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        })
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            subdistrictCode: data.value,
            subdistrictName: data.children,
            postalCode: data.postalCode
        })
    }

    onUpdateIdCard = async () => {
        if (this.state.phoneNumber.length < 6) {
            Modal.error({title: 'Error', content: "Nomor Telpon tidak valid"})
            return
        }
        if (!this.state.nib || !this.state.npwp || !this.state.businessEntityType || !this.state.businessEntityName || !this.state.phoneNumber) {
            Modal.error({title: 'Error', content: "Lengkapi data Badan Usaha, NPWP, NIB, dan nomor telpon perusahaan"})
            return
        }
        if (!this.state.subdistrictCode || !this.state.subdistrictName || !this.state.postalCode) {
            Modal.error({title: 'Error', content: "Lengkapi alamat perusahaan"})
            return
        }
        if (!this.state.imageNpwp) {
            Modal.error({title: 'Error', content: "Image NPWP"})
            return
        }
        await this.promisedSetState({submitting: true})

        const dataInsert = [
            {
                abstract: "GENERAL",
                uuid: this.state.uuid,
                npwp_code: this.state.npwp,
                npwp_image: this.state.imageNpwp,
                nib_code: this.state.nib,
                nib_pdf: this.state.pdfNib,
                website: this.state.website,
                business_entity_type: this.state.businessEntityType,
                business_entity_name: this.state.businessEntityName,
                phone_number: reformatPhoneNumber(this.state.phoneNumber),
                email: this.state.email ?? "",
                address: {
                    full_address: this.state.fullAddress,
                    province_code: this.state.provinceCode,
                    province_name: this.state.provinceName,
                    city_code: this.state.cityCode,
                    city_name: this.state.cityName,
                    district_code: this.state.districtCode,
                    district_name: this.state.districtName,
                    sub_district_code: this.state.subdistrictCode,
                    sub_district_name: this.state.subdistrictName,
                    zip_code: this.state.postalCode
                },
                contact_person: {
                    name: "",
                    phone_number: ""
                }
            }
        ]

        try {
            await businessEntityService.sendGeneralEntity(dataInsert)
            Modal.success({
                title: 'Proses Sukses',
                content: 'data telah kami terima dan akan kami proses. terimakasih telah melakukan input form.'
            })
            setTimeout(function () {
                window.location.reload()
            }, 2500)
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            })
            this.setState({
                submitting: false
            })
        }
    }

    onImageUpdate = async (data: any) => {
        if (data.id === "image_npwp") {
            this.setState({
                imageNpwp: data.image_url,
            });
        }
        if (data.id === "pdf_nib") {
            this.setState({
                pdfNib: data.image_url,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Trimitra</Breadcrumb.Item>
                            <Breadcrumb.Item>Form B2B</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className={`mt-20`} style={{backgroundColor: "#f8f8f8"}}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-20`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>Form B2B - Badan Usaha</div>
                                <br/>
                                <div className={`detail-product-caption`}>Silakan lengkapi form berikut.</div>
                                <br/>
                            </Col>
                            <Col xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 0}} xs={{span: 0}}>
                                <img src={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/setting-information.png`} style={{height: 150}} alt={`logo`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                                <Form.Item>
                                    <i className={`small-text-grey`}>Jenis Badan Usaha *</i>
                                    <Select
                                        placeholder="Pilih Jenis Badan Usaha"
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={value => this.onFieldChange("businessEntityType", value)}
                                        value={this.state.tempDegree}
                                        style={{width: "100%"}}
                                    >
                                        <Select.Option value="PT">PT</Select.Option>
                                        <Select.Option value="CV">CV</Select.Option>
                                        <Select.Option value="PD">PD</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Nama Badan Usaha *</i>
                                    <Input value={this.state.businessEntityName} placeholder="Nama Badan Usaha" onChange={event => this.onFieldChange("businessEntityName", event.target.value)}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>NIB *</i>
                                    <Input value={this.state.nib} placeholder="NIB" onChange={event => this.onFieldChange("nib", event.target.value)}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>NPWP *</i>
                                    <Input value={this.state.npwp} placeholder="NPWP" onChange={event => this.onFieldChange("npwp", event.target.value)}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`small-text-grey`}>Nomor Telpon Perushaan*</i>
                                    <Input value={this.state.phoneNumber} maxLength={17} placeholder="Format: 08xxxxxxxx" onChange={event => this.onFieldChange("phoneNumber", event.target.value)}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Email Perushaan</i>
                                    <Input placeholder={'Email'} value={this.state.email} onChange={event => this.onFieldChange("email", event.target.value)}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Website Perusahaan</i>
                                    <Input placeholder={'Alamat Website'} value={this.state.website} onChange={event => this.onFieldChange("website", event.target.value)}/>
                                </Form.Item>

                                <Divider/>
                                <Row>
                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                        <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'image_npwp'} title={`Image NPWP*`} number={this.state.npwp} name={``} hideInfo={true}/>
                                    </Col>
                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                        <UploadFile onImageUpdateProps={this.onImageUpdate} id={'pdf_nib'} title={`Pdf NIB`} number={this.state.nib} name={``} hideInfo={true}/>
                                    </Col>
                                </Row>

                            </Col>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Alamat *</i>
                                    <TextArea showCount maxLength={200} value={this.state.fullAddress} onChange={event => this.onFieldChange("fullAddress", event.target.value)} placeholder="Alamat Sesuai KTP"/>
                                </Form.Item>
                                <Form.Item style={{paddingTop: "-100px"}}>
                                    <i className={`text-small-grey-nopadding`}>Provinsi *</i>
                                    <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceCode={this.state.provinceCode} provinceName={this.state.provinceName}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Kota / Kabupaten *</i>
                                    <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.provinceCode} cityName={this.state.cityName}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Kecamatan *</i>
                                    <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.cityCode} districtName={this.state.districtName}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Kelurahan *</i>
                                    <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.districtCode} subdistrictName={this.state.subdistrictName}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Kode Pos *</i>
                                    <Input value={this.state.postalCode} placeholder="Kodepos" onChange={event => this.onFieldChange("postalCode", event.target.value)}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                                <Button loading={this.state.submitting} onClick={() => this.onUpdateIdCard()} type="primary"> Save Form</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default withRouter(FormB2b)
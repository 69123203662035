import axios from "axios";
import {baseUrlCdnUpload, xApiKeyCdnUpload} from "../../config/apiConfig/apiConfig";

class CdnApiService {
    private axios = axios.create({
        baseURL: baseUrlCdnUpload,
    });

    public async uploadIdCardBase64(inputData?: any) {
        try {
            return await this.axios.post<any>('/upload-document/base64-id-card',
                inputData,{
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyCdnUpload
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

}

export const cdnApiService = new CdnApiService();
